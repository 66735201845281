<template>
    <a-select show-search :size="size" class="custom-select" @search="search" :value="modelValue" @change="hasChanged" :show-arrow="showArrow">
        <a-select-option v-for="(opt, indx) in options" :key="indx" :value="opt[vparam]">{{opt[lparam]}}</a-select-option>
        <slot></slot>
        <a-select-option key="other" v-if="!inArray(options, modelValue, lparam, vparam) && modelValue !== null" :value="modelValue">{{modelValue}}</a-select-option>
        <a-select-option v-if="newValue !== ''" :value="newValue" key="newVal">{{newValue}}</a-select-option>
        <template #notFoundContent>No Data</template>
    </a-select>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: [String, Number]
        },
        options: {
            type: Array,
            default: () => []
        },
        vparam: {
            type: String,
            default: "value"
        },
        lparam: {
            type: String,
            default: "label"
        },
        size: {
            type: String,
            default: "small"
        },
        showArrow: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            newValue: "",
            result: [],
            oldValue: "",
        }
    },
    mounted() {
        this.oldValue = this.modelValue;
    },
    emits: ['change','update:modelValue'],
    methods: {
        search(val) {
            var param = this.lparam
            var find = this.options.findIndex(el => el[param].toLowerCase().includes(val.toLowerCase()));
            if (find <= -1) {
                this.newValue = val;
            } else {
                this.newValue = "";
            }
        },
        showNewOption() {
            var param = this.lparam
            let hasExistingOption = this.options.filter(option => !option.created).some(option => option[param] === this.newValue);
            return this.newValue !== '' && !hasExistingOption;
        },
        select(val) {
            this.$emit('update:modelValue', val)
            //this.$emit('change', val)
        },
        hasChanged(val) {
            this.$emit('update:modelValue', val)
            this.$emit('change', val, this.oldValue)
        },
        inArray(arr, value, attr, attr2) {
            var found = false;
            for(var i = 0; i < arr.length; i++) {
                if (arr[i][attr] == value || arr[i][attr2] == value) {
                    found = true;
                    break;
                }
            }
            return found;
        },
    }
}
</script>

<style>
.custom-select .ant-select-selection-selected-value {
    font-size: 12px;
}
</style>
