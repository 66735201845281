<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
            <a-button class="ml-auto" @click="openModal">New</a-button>
        </div>

        <div class="claims content bg-white shadow rounded mt-2 overflow-hidden h-full relative">
            <a-tabs v-model:activeKey="activeKey">
                <a-tab-pane key="1" tab="Active">
                    <a-table
                        :columns="columns"
                        :row-key="record => record.id"
                        :data-source="claims"
                        :pagination="false">
                        <template #status="{ record }">
                            <Select class="w-32" v-model="record.status" :options="statusOptions" vparam="value" lparam="label" @change="updateStatus(record)"></Select>
                        </template>

                        <template #completed="{ record }">
                            <a-checkbox v-model:checked="record.is_complete" @change="updateComplete(record)"></a-checkbox>
                        </template>

                        <template #actions="{record}">
                            <a-popconfirm
                                title="Are you sure delete this claim?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="deleteClaim(record)"
                            >
                                <a-button danger size="small">Delete</a-button>
                            </a-popconfirm>
                        </template>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Completed" force-render>
                    <a-table
                        :columns="columnsComplete"
                        :row-key="record => record.id"
                        :data-source="claimsCompleted"
                        :pagination="false">
                        <!--<template #status="{ record }">
                            <Select class="w-32" v-model="record.status" :options="[{label: 'Pending', value: 'pending'}]" vparam="value" lparam="label" @change="updateStatus(record)"></Select>
                        </template>-->

                        <template #completed="{ record }">
                            <a-checkbox v-model:checked="record.is_complete" @change="updateComplete(record)"></a-checkbox>
                        </template>

                        <template #actions="{record}">
                            <a-popconfirm
                                title="Are you sure delete this claim?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="deleteClaim(record)"
                            >
                                <a-button danger size="small">Delete</a-button>
                            </a-popconfirm>
                        </template>
                    </a-table>
                </a-tab-pane>
            </a-tabs>
        </div>

        <a-modal v-model:visible="newModalOpen" title="New Courier Claim" @ok="submitForm" :confirmLoading="newLoading" @cancel="linnworksId = null">
            <a-input v-model:value="linnworksId" placeholder="Linnworks ID" ref="linnInput"/>
        </a-modal>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import axios from "axios";
import Select from "../components/Select.vue";
export default defineComponent({
    name: "CourierClaims",
    components: {
        Select
    },
    computed: {
        user() {
            return this.$store.state.auth.user;
        }
    },
    data() {
        return {
            activeKey: '1',
            newModalOpen: false,
            newLoading: false,
            linnworksId: null,
            claims: [],
            claimsCompleted: [],
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'linnworks_id'
                },
                {
                    title: 'Courier',
                    dataIndex: 'postal_service_name'
                },
                {
                    title: 'Tracking ID',
                    dataIndex: 'postal_tracking_num'
                },
                {
                    title: 'Customer Name',
                    dataIndex: 'customer_name'
                },
                {
                    title: 'Customer Email',
                    dataIndex: 'customer_email'
                },
                {
                    title: 'Reference Num',
                    dataIndex: 'secondary_reference_num'
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    slots: {
                        customRender: 'status'
                    }
                },
                {
                    title: 'Completed',
                    dataIndex: 'is_complete',
                    slots: {
                        customRender: 'completed'
                    }
                },
                {
                    title: 'Actions',
                    slots: {
                        customRender: 'actions'
                    }
                }
            ],
            columnsComplete: [
                {
                    title: 'ID',
                    dataIndex: 'linnworks_id'
                },
                {
                    title: 'Courier',
                    dataIndex: 'postal_service_name'
                },
                {
                    title: 'Tracking ID',
                    dataIndex: 'postal_tracking_num'
                },
                {
                    title: 'Customer Name',
                    dataIndex: 'customer_name'
                },
                {
                    title: 'Customer Email',
                    dataIndex: 'customer_email'
                },
                {
                    title: 'Reference Num',
                    dataIndex: 'secondary_reference_num'
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    slots: {
                        customRender: 'status'
                    }
                },
                {
                    title: 'Completed',
                    dataIndex: 'is_complete',
                    slots: {
                        customRender: 'completed'
                    }
                },
                {
                    title: 'Completed By',
                    dataIndex: 'completed_by'
                },
            ],
            statusOptions: [
                {
                    label: "Claim Submitted",
                    value: "Claim Submitted"
                },
                {
                    label: "Awaiting Courier Response",
                    value: "Awaiting Courier Response"
                },
                {
                    label: "Awaiting Customer Response",
                    value: "Awaiting Customer Response"
                },
                {
                    label: "Awaiting Payment from Courier",
                    value: "Awaiting Payment from Courier"
                },
                {
                    label: "Completed",
                    value: "Completed"
                }
            ]
        }
    },
    watch: {
    },
    mounted() {
        this.getClaims()
        this.getClaimsCompleted()
    },
    methods: {
        openModal() {
            this.newModalOpen = true;
            this.$nextTick(() => {
                this.$refs.linnInput.focus()
            })
        },
        getClaims() {
            axios.get(window.api_url + '/api/dispatch/courier-claims').then(resp => {
                this.claims = resp.data.data;
            }).catch(err => {
                this.$message.error(err);
            })
        },
        getClaimsCompleted() {
            axios.get(window.api_url + '/api/dispatch/courier-claims/completed').then(resp => {
                this.claimsCompleted = resp.data.data;
            }).catch(err => {
                this.$message.error(err);
            })
        },
        submitForm() {
            this.newLoading = true;
            axios.post(window.api_url + '/api/dispatch/courier-claims', {
                id: this.linnworksId
            }).then(resp => {
                if(resp.data.success) {
                    this.linnworksId = null;
                    this.newModalOpen = false;
                    this.$message.info("Created Claim");
                    this.getClaims();
                }
            }).catch(err => {
                console.log(err)
                this.$message.error(err);
            }).finally(() => {
                this.newLoading = false;
            })
        },
        updateStatus(record) {
            console.log(record)
            axios.put(window.api_url + '/api/dispatch/courier-claims/status', {
                id: record.id,
                status: record.status
            }).then(resp => {
                console.log(resp.data)
                this.$message.info("Updated Status");
            }).catch(err => {
                this.$message.error(err);
            })
        },
        updateComplete(record) {
            console.log(record)
            axios.put(window.api_url + '/api/dispatch/courier-claims/complete', {
                id: record.id,
                value: record.is_complete,
                username: this.user.nicename
            }).then(resp => {
                console.log(resp.data)
                this.$message.info("Updated Completion Status");
                this.getClaims()
                this.getClaimsCompleted()
            }).catch(err => {
                this.$message.error(err);
            })
        },
        deleteClaim(record) {
            axios.delete(window.api_url + '/api/dispatch/courier-claims/' + record.id).then(resp => {
                if(resp.data.success) {
                    this.$message.info("Deleted Claim");
                    this.getClaims();
                }
            }).catch(err => {
                this.$message.error(err);
            })
        }
    },
});
</script>

<style lang="scss">
.ant-message-custom-content.ant-message-info {
    display: flex;
    align-items: center;
}
.claims .ant-tabs-bar {
    margin-bottom: 0
}
</style>
